var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[_c('Modal',{attrs:{"title":"Details"},model:{value:(_vm.infoModal),callback:function ($$v) {_vm.infoModal=$$v},expression:"infoModal"}},[_c('h2',[_vm._v(_vm._s(_vm.info.title))]),_c('div',{staticClass:"mb-10"},[_vm._v(" "+_vm._s(_vm.info.local_start_time)+" - "+_vm._s(_vm.info.local_end_time)+" ")]),_c('div',{staticClass:"mb-20"},[_vm._v(_vm._s(_vm.info.description))]),_c('div',{staticClass:"users"},_vm._l((_vm.info.users),function(user){return _c('div',{key:user.id,staticClass:"user"},[_c('Tooltip',{attrs:{"content":((user.first_name) + " " + (user.last_name)),"placement":"top"}},[_c('Avatar',{attrs:{"src":user.avatar.url}})],1)],1)}),0)]),_c('Modal',{attrs:{"title":"Schedule a meeting","footer-hide":""},model:{value:(_vm.scheduleModal),callback:function ($$v) {_vm.scheduleModal=$$v},expression:"scheduleModal"}},[_c('Form',{ref:"form",attrs:{"model":_vm.form}},[_c('FormItem',{attrs:{"label":"Title","rules":_vm.required,"prop":"title"}},[_c('Input',{model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_c('FormItem',{attrs:{"label":"Date","rules":[
          {
            required: true,
            type: 'array',
            message: 'Please select',
            trigger: 'blur',
            fields: {
              0: { type: 'date', required: true, message: 'Please select' },
              1: { type: 'date', required: true, message: 'Please select' }
            }
          }
        ],"prop":"date"}},[_c('div',[_c('DatePicker',{staticStyle:{"width":"100%"},attrs:{"format":"yyyy-MM-dd HH:mm","type":"datetimerange"},on:{"on-change":_vm.chooseTime},model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}})],1)]),_c('FormItem',{attrs:{"label":"Users","rules":_vm.required,"prop":"user"}},[_c('Select',{attrs:{"not-found-text":"No member avaliable during selected date range","filterable":"","multiple":"","max-tag-count":3},model:{value:(_vm.form.user),callback:function ($$v) {_vm.$set(_vm.form, "user", $$v)},expression:"form.user"}},_vm._l((_vm.members),function(item){return _c('Option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.first_name)+" "+_vm._s(item.last_name))])}),1)],1),_c('FormItem',{attrs:{"label":"Description","rules":_vm.required,"prop":"description"}},[_c('Input',{attrs:{"type":"textarea","rows":4},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('FormItem',[_c('Button',{attrs:{"loading":_vm.createLoading,"type":"primary"},on:{"click":_vm.createSubmit}},[_vm._v("Submit")])],1)],1)],1),(_vm.loading)?_c('Spin',{attrs:{"fix":""}}):_vm._e(),_c('router-link',{staticClass:"back",attrs:{"to":("/company/" + (_vm.$route.params.id))}},[_c('Icon',{attrs:{"type":"ios-arrow-back"}}),_c('span',[_vm._v("Return to Company Details")])],1),_c('div',{staticClass:"box"},[_c('div',{staticClass:"right"},[(_vm.role === 'attendee' && _vm.is_administrator)?_c('div',{staticClass:"mb-20"},[_c('Button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.scheduleModal = true}}},[_vm._v("SCHEDULE A MEETING")])],1):_vm._e(),_c('FullCalendar',{attrs:{"options":_vm.calendarOptions},on:{"eventClick":_vm.eventClick}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }