<template>
  <div class="relative">
    <Modal v-model="infoModal" title="Details">
      <h2>{{ info.title }}</h2>
      <div class="mb-10">
        {{ info.local_start_time }} - {{ info.local_end_time }}
      </div>
      <div class="mb-20">{{ info.description }}</div>
      <div class="users">
        <div class="user" :key="user.id" v-for="user in info.users">
          <Tooltip
            :content="`${user.first_name} ${user.last_name}`"
            placement="top"
          >
            <Avatar :src="user.avatar.url" />
          </Tooltip>
        </div>
      </div>
    </Modal>

    <Modal v-model="scheduleModal" title="Schedule a meeting" footer-hide>
      <Form ref="form" :model="form">
        <FormItem label="Title" :rules="required" prop="title">
          <Input v-model="form.title" />
        </FormItem>

        <FormItem
          label="Date"
          :rules="[
            {
              required: true,
              type: 'array',
              message: 'Please select',
              trigger: 'blur',
              fields: {
                0: { type: 'date', required: true, message: 'Please select' },
                1: { type: 'date', required: true, message: 'Please select' }
              }
            }
          ]"
          prop="date"
        >
          <div>
            <DatePicker
              @on-change="chooseTime"
              format="yyyy-MM-dd HH:mm"
              type="datetimerange"
              v-model="form.date"
              style="width: 100%"
            ></DatePicker>
          </div>
        </FormItem>

        <FormItem label="Users" :rules="required" prop="user">
          <Select
            not-found-text="No member avaliable during selected date range"
            filterable
            multiple
            v-model="form.user"
            :max-tag-count="3"
          >
            <Option v-for="item in members" :value="item.id" :key="item.id"
              >{{ item.first_name }} {{ item.last_name }}</Option
            >
          </Select>
        </FormItem>

        <FormItem label="Description" :rules="required" prop="description">
          <Input type="textarea" :rows="4" v-model="form.description" />
        </FormItem>

        <FormItem>
          <Button :loading="createLoading" type="primary" @click="createSubmit"
            >Submit</Button
          >
        </FormItem>
      </Form>
    </Modal>

    <Spin v-if="loading" fix></Spin>
    <router-link :to="`/company/${$route.params.id}`" class="back">
      <Icon type="ios-arrow-back" />
      <span>Return to Company Details</span>
    </router-link>

    <div class="box">
      <!-- <div class="left"></div> -->

      <div class="right">
        <div v-if="role === 'attendee' && is_administrator" class="mb-20">
          <Button type="primary" @click="scheduleModal = true"
            >SCHEDULE A MEETING</Button
          >
        </div>

        <FullCalendar :options="calendarOptions" @eventClick="eventClick" />
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
const {
  _queryAvailableUser,
  _createMeeting,
  _queryAnyExhibitorCompanyAgenda
} = api;

import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import moment from "moment";
import { mapState } from "vuex";

export default {
  components: {
    FullCalendar
  },
  created() {
    const id = this.$route.params.id;
    this.id = id;
  },
  computed: {
    ...mapState("user", ["role", "is_administrator"])
  },
  data() {
    return {
      info: {},
      infoModal: false,

      createLoading: false,
      members: [],

      form: {
        title: null,
        description: null,
        date: [],
        user: []
      },

      required: [
        {
          required: true,
          message: "Please fill"
        }
      ],
      scheduleModal: false,
      loading: false,

      eventsUnix: [],

      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin // needed for dateClick
        ],
        headerToolbar: {
          left: "",
          center: "title",
          right: "prev,next today"
        },
        views: {
          timeGrid7Day: {
            type: "timeGrid",
            duration: { days: 7 },
            buttonText: "7 day"
          }
        },
        initialView: "timeGrid7Day",
        events: this.queryCalendar,
        eventClick: this.eventClick,
        select: this.handleDateSelect,
        selectAllow: this.selectAllow,
        selectable: true
      }
    };
  },
  methods: {
    selectAllow(selectInfo) {
      // disable
      const startUnix = moment.utc(selectInfo.startStr).unix();
      const endUnix = moment.utc(selectInfo.endStr).unix();

      let flag = true;
      this.eventsUnix.forEach(item => {
        if (
          (item.startUnix > startUnix && item.startUnix < endUnix) ||
          (item.endUnix > startUnix && item.endUnix < endUnix)
        ) {
          flag = false;
        }
      });

      return flag;
    },
    handleDateSelect(selectInfo) {
      this.scheduleModal = true;
      this.form.date = [
        new Date(selectInfo.startStr),
        new Date(selectInfo.endStr)
      ];
      this.chooseTime();
    },
    eventClick(e) {
      this.info = JSON.parse(JSON.stringify(e.event.extendedProps));
      this.infoModal = true;
    },
    queryCalendar(e, successCallback) {
      this.loading = true;
      _queryAnyExhibitorCompanyAgenda({
        id: this.id,
        start_time: moment(e.start)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
        end_time: moment(e.end)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss")
      })
        .then(response => {
          const { data } = response;
          this.eventsUnix = data.map(item => {
            return {
              startUnix: moment.utc(item.start_time).unix(),
              endUnix: moment.utc(item.end_time).unix()
            };
          });

          successCallback(
            data.map(item => {
              return {
                title: item.title,
                start: moment.utc(item.start_time).format(),
                end: moment.utc(item.end_time).format(),
                // more info
                extendedProps: {
                  title: item.title,
                  description: item.description,
                  local_start_time: moment
                    .utc(item.start_time)
                    .local()
                    .format("HH:mm"),
                  local_end_time: moment
                    .utc(item.end_time)
                    .local()
                    .format("HH:mm"),
                  users: item.users
                }
              };
            })
          );
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    chooseTime() {
      const start_time = moment(this.form.date[0])
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");

      const end_time = moment(this.form.date[1])
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");

      _queryAvailableUser({
        start_time,
        end_time
      })
        .then(response => {
          const { data } = response;
          this.members = data;
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        });
    },
    createSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.createLoading = true;

          const start_time = moment(this.form.date[0])
            .utc()
            .format("YYYY-MM-DD HH:mm:ss");

          const end_time = moment(this.form.date[1])
            .utc()
            .format("YYYY-MM-DD HH:mm:ss");

          _createMeeting({
            id: this.id,
            title: this.form.title,
            description: this.form.description,
            user: [...this.form.user],
            start_time,
            end_time
          })
            .then(() => {
              this.$Message.success("success");
              this.scheduleModal = false;
            })
            .catch(({ message }) => {
              this.$Message.error(message);
            })
            .finally(() => {
              this.createLoading = false;
            });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.relative {
  position: relative;
  min-height: 300px;
}

.back {
  color: @primary-color;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 14px 24px;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  background: #fff;
}

.box {
  display: flex;
  background: #fff;
  .left {
    width: 300px;
    border-right: 1px solid #ccc;
    padding: 20px;
  }
  .right {
    flex: 1;
    padding: 20px;
  }
}

.users {
  display: flex;
  .user {
    margin-right: 10px;
  }
}
</style>
